import { Injectable } from '@angular/core';
import { ChartSaleReportRequest, AccountReportRequest, SaleProfitCustomerReportRequest, WSaleReportRequest, SaveTargetRequest, OrSummaryReportRequest, TargetByCompanyRequest, TargetByCompanyDateRequest, BillDetailDailyReportRequest, SaleCustomerReportRequest } from './SaleReportObject';
import { GlobalDataService } from '../globaldata.service';
import { PostService } from './PostService';

@Injectable()
export class SaleReportService {

    saleReportUrl: string;
    stockReportUrl: string;
    targerUrl: string;

    constructor(private gd: GlobalDataService, private ps: PostService) {
        this.saleReportUrl = this.gd.shareObj['SaleReportApi'];
        this.targerUrl = this.gd.shareObj['TargetApi'];
        this.stockReportUrl = this.gd.shareObj['StockApi'];
    }

    getBillDetailDailyReport(req: BillDetailDailyReportRequest) {
        req.company_id = this.gd.shareObj['selectedCompany'];
        return this.ps.post(this.saleReportUrl + "dailybilldetailreport", req);
    }

    getBillDailyReport(req: BillDetailDailyReportRequest) {
        req.company_id = this.gd.shareObj['selectedCompany'];
        return this.ps.post(this.saleReportUrl + "dailybillreport", req);
    }

    getDailyReport(req: BillDetailDailyReportRequest) {
        req.company_id = this.gd.shareObj['selectedCompany'];
        return this.ps.post(this.saleReportUrl + "dailyReport", req);
    }

    getDailyDERReport(req: BillDetailDailyReportRequest) {
        req.company_id = this.gd.shareObj['selectedCompany'];
        return this.ps.post(this.saleReportUrl + "derReport", req);
    }

    getDailyDERDetailReport(req: BillDetailDailyReportRequest) {
        req.company_id = this.gd.shareObj['selectedCompany'];
        return this.ps.post(this.saleReportUrl + "derDetailReport", req);
    }

    getDERHeaderProfitReport(req: BillDetailDailyReportRequest) {
        req.company_id = this.gd.shareObj['selectedCompany'];
        return this.ps.post(this.saleReportUrl + "derHeaderProfitReport", req);
    }

    getDERHeaderProfitReport2(req: BillDetailDailyReportRequest) {
        req.company_id = this.gd.shareObj['selectedCompany'];
        return this.ps.post(this.saleReportUrl + "derHeaderProfitReport2", req);
    }


    getSaleSummaryReport(req: BillDetailDailyReportRequest) {
        req.company_id = this.gd.shareObj['selectedCompany'];
        return this.ps.post(this.saleReportUrl + "saleSummaryReport", req);
    }

    getOrTopHeadReport(req: BillDetailDailyReportRequest) {
        req.company_id = this.gd.shareObj['selectedCompany'];
        return this.ps.post(this.saleReportUrl + "orTopHeadReport", req);
    }

    getMovementReport(req: BillDetailDailyReportRequest) {
        req.company_id = this.gd.shareObj['selectedCompany'];
        return this.ps.post(this.saleReportUrl + "movement", req);
    }

    getFollowingReport(req: BillDetailDailyReportRequest) {
        req.company_id = this.gd.shareObj['selectedCompany'];
        return this.ps.post(this.saleReportUrl + "following", req);
    }

    getBestSellerReport(req: BillDetailDailyReportRequest) {
        return this.ps.post(this.saleReportUrl + "bestseller", req);
    }

    getAccBillDetailReport(req: BillDetailDailyReportRequest) {
        req.company_id = this.gd.shareObj['selectedCompany'];
        return this.ps.post(this.saleReportUrl + "accBillDetailReport", req);
    }

    getCashireDailyReport(req: BillDetailDailyReportRequest) {
        req.company_id = this.gd.shareObj['selectedCompany'];
        return this.ps.post(this.saleReportUrl + "cashireDailyReport", req);
    }

    getWsaleCashireDailyReport(req: WSaleReportRequest) {
        return this.ps.post(this.saleReportUrl + "wsaleCashireDailyReport", req);
    }


    getTargetByCompany(req: TargetByCompanyRequest) {
        req.company_id = this.gd.shareObj['selectedCompany'];
        return this.ps.post(this.targerUrl + "getTargetByCompany", req);
    }

    saveTarget(req: SaveTargetRequest) {
        return this.ps.post(this.targerUrl + "saveTarget", req);
    }

    getOrSummaryReport(req: OrSummaryReportRequest) {
        req.company_id = this.gd.shareObj['selectedCompany'];
        return this.ps.post(this.saleReportUrl + "OrSummaryReport", req);
    }

    getTargetByCompanyDate(req: TargetByCompanyDateRequest) {
        req.company_id = this.gd.shareObj['selectedCompany'];
        return this.ps.post(this.targerUrl + "getTargetByCompanyDate", req);
    }

    getAccbilingReport(req: BillDetailDailyReportRequest) {
        req.company_id = this.gd.shareObj['selectedCompany'];
        return this.ps.post(this.saleReportUrl + "accbilingReport", req);
    }

    getAccountingMonthly(req: AccountReportRequest) {
        return this.ps.post(this.saleReportUrl + "accountingMonthly", req);
    }

    getAccountingMonthlySummary(req: AccountReportRequest) {
        return this.ps.post(this.saleReportUrl + "getAccountingMonthlySummary", req);
    }

    getChartReport(req: ChartSaleReportRequest) {
        return this.ps.post(this.saleReportUrl + "chartReport", req);
    }

    getBillChangeReport(req: BillDetailDailyReportRequest) {
        req.company_id = this.gd.shareObj['selectedCompany'];
        return this.ps.post(this.saleReportUrl + "billChangeReport", req);
    }

    getBillMinusReport(req: BillDetailDailyReportRequest) {
        req.company_id = this.gd.shareObj['selectedCompany'];
        return this.ps.post(this.saleReportUrl + "billMinusReport", req);
    }


    getNewCustomer(req: BillDetailDailyReportRequest) {
        req.company_id = this.gd.shareObj['selectedCompany'];
        return this.ps.post(this.saleReportUrl + "newCustomer", req);
    }


    getNewProduct(req: BillDetailDailyReportRequest) {
        req.company_id = this.gd.shareObj['selectedCompany'];
        return this.ps.post(this.stockReportUrl + "newProductStock", req);
    }

    getSaleByCustomer(req: SaleCustomerReportRequest) {
        return this.ps.post(this.saleReportUrl + "saleByCustomer", req);
    }

    getSaleByCustomerType(req: SaleCustomerReportRequest) {
        return this.ps.post(this.saleReportUrl + "saleByCustomerType", req);
    }


    getSaleProfitByCustomer(req: SaleProfitCustomerReportRequest) {
        req.company_id = this.gd.shareObj['selectedCompany'];
        return this.ps.post(this.saleReportUrl + "saleProfitByCustomer", req);
    }

    uploadImage(url: string, form: FormData) {
        return this.ps.postForm(url, form);
    }

}
